import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

import {AppStateService} from '@core/modules';
import {AppRouterService} from '@core/services';
import {LoginParams} from '@models/auth';

@Injectable()
export class UnAuthGuard  {

  constructor(
    private readonly appStateService: AppStateService,
    private readonly appRouterService: AppRouterService,
    private readonly router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.isAuthenticated(next);
  }

  private isAuthenticated(snapshot: ActivatedRouteSnapshot): boolean {
    const isAuth = this.appStateService.isAuthenticated;
    if (isAuth) {
      const {returnUrl} = snapshot.queryParams as LoginParams;
      if (returnUrl) {
        try {
          this.router.navigateByUrl(returnUrl);
        } catch (e) {
          this.appRouterService.navigateToStartPageByRole();
        }
        return true;
      }
      this.appRouterService.navigateToStartPageByRole();
      return true;
    }
    return false;
  }

}
